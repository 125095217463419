
<template>
  <div>
  
    
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline"><h3>DADOS PESSOAIS</h3></div>
          </v-card-title>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">Nome<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <v-text-field color="primary" label="Sua resposta" v-model="done_personal_info_attributes.name" :rules="required"></v-text-field>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">País<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <v-radio-group v-model="done_personal_info_attributes.country">
              <v-radio
                v-for="item in countryes"
                :key="item.value"
                :value="item.value"
                :label='item.text'
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">Estado<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <v-text-field color="primary" label="Sua resposta" v-model="done_personal_info_attributes.state" :rules="required"></v-text-field>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">Cidade<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <v-text-field color="primary" label="Sua resposta" v-model="done_personal_info_attributes.city" :rules="required"></v-text-field>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">Email<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <span class="text-subtitle-2" v-if="$route.name == 'GhostRating'">Certifique-se que a resposta do email será o mesmo do e-mail cadastrado.</span>
            <v-text-field color="primary" label="Sua resposta" v-model="done_personal_info_attributes.email" :rules="emailRules"></v-text-field>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">Telefone<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <v-text-field color="primary" label="Sua resposta" v-model="done_personal_info_attributes.phone" :rules="required"></v-text-field>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3" v-if="$route.name != 'GhostRating'">
        <v-card>
          <v-card-title>
            <div class="text-overline">Curriculum<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <v-switch
              v-model="switch_curriculum"
              :label="switch_curriculum ? 'Alterar para arquivo' : 'Alterar para link'"
            ></v-switch>
            <v-text-field v-if="switch_curriculum" color="primary" prepend-icon="mdi-link" label="Curriculum Lattes" v-model="done_personal_info_attributes.curriculum_link" :rules="requiredUrl"></v-text-field>
            <v-file-input
              v-if="!switch_curriculum" 
              label="Curriculum em anexo"
              accept="image/*, application/pdf" id="curriculum_file" ref="curriculum_file" @change="handleChangeCurriculumFile"
              truncate-length="15"
              :rules="[ruleFile(), ruleSize()]"
            ></v-file-input>
          </v-card-text>
        </v-card>
      </div>
      <div class="ml-auto mr-auto pt-3">
        <v-card>
          <v-card-title>
            <div class="text-overline">Data de nascimento<span style="color: red;">*</span></div>
          </v-card-title>
          <v-card-text>
            <date-picker 
              :active_picker="'YEAR'" 
              :max="$dayjs().subtract(18, 'year').format('YYYY-MM-DD')" 
              label="Data de Nascimento" 
              @datePicked="handleDatePicked" 
              :rules="required"
            >
            </date-picker>
          </v-card-text>
        </v-card>
      </div>
    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import validations from '@/mixins/validations';

export default {
  name: 'PersonalInfo',
  props: {
  },
  mixins: [
    validations
  ],
  components: {
  },
  data() {
    return {
      curriculum_file_local_url: null,
      switch_curriculum: true,
      countryes:[
        { value: 'brazilian', text: 'Brasil'},
        { value: 'argentine', text: 'Argentina'},
        { value: 'colombian', text: 'Colombia'},
        { value: 'uruguayan', text: 'Uruguai'},
        { value: 'paraguayan', text: 'Paraguai'},
        { value: 'other', text: 'Outro'},
      ]
    }
  },
  methods: {
    handleChangeCurriculumFile(Nv){
      let file = Nv
      // let file = this.avatar
      let url = window.URL.createObjectURL(file);
      this.curriculum_file_local_url = url
      this.done_personal_info_attributes.curriculum_file = file
    },
    handleDatePicked(date){
      this.done_personal_info_attributes.birthdate = this.$dayjs(date).format('YYYY/MM/DD')
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    ...mapState({
      done_personal_info_attributes: state=> state.DoneForm.done_personal_info_attributes,
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
  },
  watch: {
  },
  mounted() {
    this.done_personal_info_attributes.birthdate = this.$dayjs().format('YYYY/MM/DD')
  },
}
</script>

<style scoped>

</style>